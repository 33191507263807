import Head from 'next/head';
// import Navbar from '../components/navbar';
import { Navbar } from "flowbite-react";



const name = 'Joshua Pereyda';
export const siteTitle = 'Next.js Sample Website';

export default function Layout({ children, home, app }) {
  return (
    <div>
      <Navbar
        fluid={true}
        rounded={true}
      >
        <Navbar.Brand href="/">
          <img
            src="https://flowbite.com/docs/images/logo.svg"
            className="mr-3 h-6 sm:h-9"
            alt="Flowbite Logo"
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            10x Planner
          </span>
        </Navbar.Brand>
        {/* <Navbar.Toggle />
              <Navbar.Collapse>
                {!app &&
                  <Navbar.Link
                    href="/"
                    active={true}
                  >
                    Home
                  </Navbar.Link> 
                }
                {!app &&
                  <Navbar.Link href="/about">
                    About
                  </Navbar.Link>
                }
                {!app &&
                  <Navbar.Link href="/pricing">
                    Pricing
                  </Navbar.Link>
                }
                {!app &&
                  <Navbar.Link href="/contact">
                    Contact
                  </Navbar.Link>
                }
                {
                  <Navbar.Link href="/planner">
                    Planner
                  </Navbar.Link>
                }
                {app &&
                  <Navbar.Link href="/planner/history">
                    History
                  </Navbar.Link>
                }
                {app &&
                  <Navbar.Link href="/settings/account  ">
                    Settings
                  </Navbar.Link>
                }
                {app &&
                  <Navbar.Link href="/profile  ">
                    Profile
                  </Navbar.Link>
                }
              </Navbar.Collapse> */}
      </Navbar>

      <title>10x Planner App</title>
      <link rel="icon" href="/favicon.ico" />
      <meta
        property={"og:image"}
        content={`https://og-image.vercel.app/${encodeURI(
          siteTitle,
        )}.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg`}
      />
      <meta name={"og:title"} content={siteTitle} />
      <meta name={"twitter:card"} content={"summary_large_image"} />
      <div >
        {/*<header className={styles.header}>*/}
        {/*    {home ? (*/}
        {/*        <>*/}
        {/*            <Image*/}
        {/*                priority*/}
        {/*                src={"/images/profile.jpg"}*/}
        {/*                className={utilStyles.borderCircle}*/}
        {/*                height={144}*/}
        {/*                width={144}*/}
        {/*                alt={name}*/}
        {/*            />*/}
        {/*            <h1 className={utilStyles.heading2Xl}>{name}</h1>*/}
        {/*        </>*/}
        {/*    ) : (*/}
        {/*        <>*/}
        {/*            <Link href={"/"}>*/}
        {/*                <Image*/}
        {/*                    priority*/}
        {/*                    src={"/images/profile.jpg"}*/}
        {/*                    className={utilStyles.borderCircle}*/}
        {/*                    height={108}*/}
        {/*                    width={108}*/}
        {/*                    alt={name}*/}
        {/*                />*/}
        {/*            </Link>*/}
        {/*            <h2 className={utilStyles.headingLg}>*/}
        {/*                <Link href={"/"} className={utilStyles.colorInherit}>*/}
        {/*                    {name}*/}
        {/*                </Link>*/}
        {/*            </h2>*/}
        {/*        </>*/}
        {/*    )}*/}
        {/*</header>*/}
        <main>{children}</main>
        {/* {!home && (
                <div className={styles.backToHome}>
                    <Link href={"/"}>← Back to home</Link>
                </div>
            )} */}
      </div>
    </div>
  );
}